<template>
  <addDetail :formEdit="formEdit"></addDetail>
</template>

<script>
import addDetail from './components/addDetail'
import authRouter from 'mixins/authRouter'
export default {
  name: 'agentedit',
  mixins: [authRouter],
  components: {
    addDetail
  },
  data () {
    return {
      formEdit: {}
    }
  },
  created () {
    this.$store.commit("layouts/setPathList", [{ name: '经纪人列表', path: '/agentmanage/agentlist' }, { name: '编辑' }]);
    this.$store.commit("layouts/changeBreadcrumb", true)
  },
  destroyed () {
    this.$store.commit("layouts/changeBreadcrumb", false)
  },
  watch: {
    $route: {
      handler: function ({ params }) {
        if (!params.id) {
          this.$router.go(-1)
          return
        }
        this.formEdit.name = params.name
        this.formEdit.phone = params.phone
        this.formEdit.inviter_id = params.inviter_id
        this.formEdit.alipay_account = params.alipay_account
        this.formEdit.separate_ratio = params.separate_ratio
        this.formEdit.role = params.role
        this.formEdit.status = params.status
        this.formEdit.id = params.id
      },
      immediate: true
    },
  }
}
</script>

<style lang="less" scoped>
</style>